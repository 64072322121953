import BaseModel from "@/models/BaseModel";

export default class Company extends BaseModel {
  static entity = "companies";
  static modelPath = "companies";
  static formKey = "company";

  static fields() {
    return {
      id: this.uid(),
      name: this.string(""),
      registration_no: this.string(""),
      free_delivery: this.boolean(false)
    };
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/brands`);
      }
    }
  };
}
