import BaseModel from "./BaseModel";
import Shop from "./Shop";
import VatTax from "./VatTax";
import Category from "./Category";
import ProductCategory from "./ProductCategory";
import ProductVariant from "./ProductVariant";

export default class Product extends BaseModel {
  static entity = "products";
  static modelPath = "products";
  static formKey = "product";
  static apiConfig = {
    actions: {
      cloneProduct(shopId, productId) {
        return this.post(`/shops/${shopId}/products/${productId}/clone`, {
          actions: {}
        });
      }
    }
  };

  get getProductPreviewUrl() {
    return `${this.shopBaseUrl}/product/${this.id}`;
  }

  get getDefaultCategoryName() {
    return this.default_category ? this.default_category.name : "brak";
  }

  get priceBrutto() {
    const vatTax = VatTax.find(this.vat_tax_id);
    let taxValue = 0;
    if (vatTax) {
      taxValue = (vatTax.value / 100) * this.price_netto;
    }
    return (this.price_netto + taxValue).toFixed(2);
  }

  get getCover() {
    if (this.cover !== "") {
      return this.baseImageUrl + this.cover;
    } else {
      const [picture] = this.product_pictures;
      return picture !== undefined ? picture.getImageUrl : null;
    }
  }

  static fields() {
    return {
      id: this.uid(),
      shop_id: this.attr(null),
      vat_tax_id: this.attr(null),
      brand_id: this.attr(null),
      default_category_id: this.attr(null),
      ean: this.string(""),
      symbol: this.string(""),
      weight: this.attr(null),
      name: this.string(""),
      youtube_link: this.string(""),
      slug: this.attr(""),
      seo_title: this.attr(""),
      seo_description: this.attr(""),
      short_description: this.string(""),
      description: this.string(""),
      sale: this.boolean(false),
      price_netto: this.number(0),
      promo_price_netto: this.number(0),
      items: this.number(0),
      is_active: this.boolean(false),
      month_star: this.boolean(false),
      deleted: this.boolean(false),
      shop: this.belongsTo(Shop, "shop_id"),
      vat_tax: this.belongsTo(VatTax, "vat_tax_id"),
      default_category: this.belongsTo(Category, "default_category_id"),
      // product_categories: this.hasMany(ProductCategory,'category_id'),
      cover: this.string(""),
      product_variants: this.hasMany(ProductVariant, "product_id"),
      product_categories: this.belongsToMany(
        Category,
        ProductCategory,
        "product_id",
        "category_id"
      )
    };
  }

  static getProductListQuery(shopId, data) {
    const config = {
      save: false
    };
    return this.api()
      .get(`shops/${shopId}/products/query`, data, config)
      .then((response) => {
        const data = response.response.data;
        Product.deleteAll();
        Product.insert({ data: data.products });
        return parseInt(data.count_total);
      });
  }

  static getProductList(shopId, data) {
    const config = {
      save: false
    };
    return this.api()
      .get(`shops/${shopId}/products/query`, data, config)
      .then((response) => {
        console.log(response);
        return response.response.data;
      });
  }

  static getProductVariantList(shopId, data) {
    const config = {
      save: false
    };
    return this.api()
      .get(`shops/${shopId}/products/query_variants`, data, config)
      .then((response) => {
        console.log(response);
        return response.response.data;
      });
  }

}
