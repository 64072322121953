import Product from "./Product";
import BaseModel from "@/models/BaseModel";

export default class ProductOfMonth extends BaseModel {
  static entity = "product_of_month";
  static modelPath = "product_of_months";
  static formKey = "product_of_month";


  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      position: this.number(0),
      product: this.belongsTo(Product, "product_id")
    };
  }

  static remove(shopId, productOfMonthId) {
    return this.api().delete(
      `/shops/${shopId}/product_of_months/${productOfMonthId}`,
      {
        delete: productOfMonthId
      }
    );
  }

  static add(shopId, productId) {
    const data = {
      product_of_month: {
        product_id: productId
      }
    };

    return this.api().post(
      `/shops/${shopId}/product_of_months`,
      data
    );

  }

  static setById(shopId, productOfMonthId, form) {
    const data = {
      product_of_month: form
    };
    return this.api().put(
      `/shops/${shopId}/product_of_months/${productOfMonthId}`,
      data
    );
  }


  static setPosition(shopId, productOfMonthId, position) {
    const data = {
      product_of_month: {
        position
      }
    };
    return this.api().put(
      `/shops/${shopId}/product_of_months/${productOfMonthId}/set_position`,
      data
    );
  }

}
