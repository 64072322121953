import BaseModel from "./BaseModel";
import ProductVariant from "@/models/ProductVariant";
import { keysToSnake } from "~/helpers/caseConverter";

export default class ProductVariantCustomPrice extends BaseModel {
  static entity = "product_variant_custom_prices";
  static modelPath = "product_variant_custom_prices";
  static formKey = "product_variant_custom_price";

  static fields() {
    return {
      id: this.uid(),
      price_net: this.number(0),
      product_variant_id: this.attr(null),
      product_variant: this.belongsTo(ProductVariant, "product_variant_id")
    };
  }

  // static add(shopId, productId, productVariantId, productVariantCustomPriceData) {
  //   const data = {
  //     product_variant_custom_price: productVariantCustomPriceData
  //   };
  //   return this.api().post(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_custom_prices`, data)
  //     .then((data) => {
  //       // console.log(data.response.data.product_features);
  //       // ProductFeature.insertProductFeaturesValues(data.response.data.product_features);
  //       return data;
  //     });
  //   ;
  // }

  static add(shopId, productId, productVariantId, customerId) {
    const data = {
      product_variant_custom_price: {
        customer_id: customerId
      }
    };
    return this.api().post(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_custom_prices/`, data);
  }

  static remove(shopId, productId, productVariantId, productVariantCustomPriceId) {
    return this.api().delete(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_custom_prices/${productVariantCustomPriceId}`, {
      delete: productVariantCustomPriceId
    });
  }

  static setById(shopId, productId, productVariantId, productVariantCustomPriceId, form) {
    const data = {};
    data[this.formKey] = keysToSnake(form);
    return this.api().put(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_custom_prices/${productVariantCustomPriceId}`, data);
  }

  static fetchForCustomer(shopId, customerId) {
    ProductVariantCustomPrice.deleteAll();
    return this.api().get(`/shops/${shopId}/products/list_product_variant_custom_prices_for_customer/${customerId}`);
  }


}
